export const breakpoints = {
  XS: 'xs',
  SM: 'sm',
  MD: 'md',
  LG: 'lg'
};

export const breakpointsValues = {
  XS: 0,
  SM: 48,
  MD: 64,
  LG: 75
};
