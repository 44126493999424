export default {
  banner: 'PdBanner',
  glossary: 'PdGlossary',
  iconContact: 'PdIconAndContact',
  headquarter: 'PdHeadquarter',
  contactProfile: 'PdContactProfile',
  cardLogo: 'PdCardLogo',
  richTextMultimedia: 'PdRichTextMultimedia',
  tabsDownload: 'PdFile',
  secondaryBanner: 'PdBanner',
  mainBannerCanvas: 'PdBanner',
  paragraphBanner: 'PdBannerParagraph',
  collapsable: 'PdCollapsable',
  simpleDownload: 'PdSimpleDownload',
  richText: 'PdRichText',
  richParagraph: 'PdRichParagraph',
  richList: 'PdRichList',
  logoLink: 'PdLogoLink',
  stepByStep: 'PdStepByStep',
  links: 'PdDownloadable',
  iconRichText: 'PdIconRichText',
  richTextCard: 'PdRichTextCard',
  macroCard: 'PdMacroCard',
  photography: 'PdPhotography'
};

export const filtersTypeTabsDownload = {
  category: 'hasCategoryFilter',
  date: 'hasDateFilter'
};
