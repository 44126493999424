import { createReducer, createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  setModalIsOpen: ['modalIsOpen'],
  setModalDownload: ['modalDownload'],
  setModalRedirect: ['modalRedirect']
});

export default Creators;

export const INITIAL_STATE = {
  modalIsOpen: false,
  modalDownload: {
    duration: 4,
    isOpen: false,
    title: undefined,
    description: undefined
  },
  modalRedirect: {
    isOpen: false,
    title: undefined,
    description: undefined,
    button: { text: undefined },
    action: () => {}
  }
};

export const setModalIsOpen = (state, { modalIsOpen }) => ({
  ...state,
  modalIsOpen
});

export const setModalDownload = (state, { modalDownload }) => ({
  ...state,
  modalDownload
});

export const setModalRedirect = (state, { modalRedirect }) => ({
  ...state,
  modalRedirect
});

export const HANDLERS = {
  [Types.SET_MODAL_IS_OPEN]: setModalIsOpen,
  [Types.SET_MODAL_DOWNLOAD]: setModalDownload,
  [Types.SET_MODAL_REDIRECT]: setModalRedirect
};

export const reducer = createReducer(INITIAL_STATE, HANDLERS);
