import { types } from '@config/constants';
import absolutePaths from '@config/constants/paths';
import canvasComponents from '@config/constants/canvasComponents';

export * from './font';
export * from './languages';
export * from './breakpoints';
export * from './localMarket';
export * from './canvasComponents';

export const paths = absolutePaths;
export const canvasComponentsIdentifiers = canvasComponents;

export const QUICK_LINKS_MAX_LENGTH = 5;
export const NUMBER_OF_NEWS_TO_FETCH = 10;

export const BVC_INVESTIGATIONS_CATEGORY = 'bvc-investigations';

export const noveltyTypes = {
  SQUARE: 'square',
  RECTANGLE: 'rectangle'
};

export const colorTypes = {
  DARK: 'dark',
  NORMAL: 'normal',
  LIGHT: 'light'
};

export const placements = {
  START: 'start',
  MIDDLE: 'middle',
  END: 'end'
};

export const alignments = {
  LEFT: 'left',
  RIGHT: 'right'
};

export const borderRadius = {
  NONE: 'none',
  BUTTON: 'button',
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
  TOTAL: 'total',
  QUICK_LINKS: 'quickLinks'
};

export const themes = {
  LIGHT: 'LIGHT',
  DARK: 'DARK'
};

export const tabMinWidth = {
  xs: '100px',
  sm: '100px',
  md: '80px',
  lg: '80px'
};

export const sockets = {
  marketInformationN1: {
    [types.DIV]: `${types.DIV}.MarketInformation`,
    [types.RF_PUB]: `${types.RF_PUB}.MarketInformationRfPub`,
    [types.DER]: `${types.DER}.MarketInformationDer`,
    [types.RV]: `${types.RV}.MarketInformation1RV`
  },
  marketInformationN3EventToSubscribe: {
    [types.INDEXES]: {
      indexrv: `${types.INDEXES}.MarketInformationLvl3IndexesRv`,
      indexrfpub: `${types.INDEXES}.MarketInformationLvl3IndexesRfPub`,
      money_market: `${types.INDEXES}.MarketInformationLvl3IndexesColIbr`,
      summary: `${types.INDEXES}.MarketInformationLvl3Summary`,
      graphic: `${types.INDEXES}.MarketInformationLvl3Graphic`
    },
    [types.RV]: {
      box: `${types.RV}.MarketInformation3RV`,
      summary: `${types.RV}.TableGraphicInformation3RV`
    },
    [types.DER]: {
      graphic: `${types.DER}.MarketInformationDerLvl3`
    },
    [types.RF_PUB]: {
      box: `${types.RF_PUB}.MarketInformation3Rf`,
      pricesAndVolumes: `${types.RF_PUB}.TableGraphicInformation3Rf`
    }
  },
  marketInformationN3EventToEmit: {
    [types.DER]: {
      graphic: 'MarketInformation3DerSymbol'
    },
    [types.RV]: {
      box: 'MarketInformation3RVSymbol',
      summary: 'MarketInformation3RVSymbol'
    },
    [types.RF_PUB]: {
      box: 'MarketInformation3RFSymbol'
    }
  },
  authenticate: {
    [types.RV]: `${types.RV}.authenticate`,
    eventName: 'authenticate'
  }
};

export const socketsEvents = {
  marketInformation1Rv: 'MarketDataRv',
  marketInformationRfPub: 'MarketDataRfPub',
  marketInformationDer: 'MarketDataDer',
  marketInformationLvl1IndexesRfPub: 'MarketInformationLvl1IndexesRfPub',
  marketInformationLvl1IndexesRv: 'MarketInformationLvl1IndexesRv',
  marketInformationLvl1IndexesColIbr: 'MarketInformationLvl1IndexesColIbr'
};

export const TOOLTIP_CHART_DIMENSIONS = {
  MEDIUM_SHORT: { height: 105, width: 140 },
  MEDIUM: { height: 140, width: 140 },
  MEDIUM_LARGE: {
    height: 160,
    width: 140
  },
  SMALL: { height: 105, width: 110 }
};

export const reportsAndBulletinsConstants = {
  CMS_LAYOUT: 'reports_and_bulletins'
};

export const financialInformationConstants = {
  CMS_LAYOUT: 'financial_information'
};

export const contactUsConstants = {
  CMS_LAYOUT: 'contact_us'
};

export const nonTradingDaysConstans = {
  CMS_LAYOUT: 'non_trading_days',
  NON_TRADING_DAY_FILTER: 'Non-Trading Day'
};

export const installersAndHandBooksConstants = {
  CMS_LAYOUT: 'installers_and_handbooks'
};

export const issuersListLocalMarketConstants = {
  CMS_LAYOUT: 'issuers_list_local_market',
  API_TYPE: 'Local'
};

export const issuersListGlobalMarketConstants = {
  CMS_LAYOUT: 'issuers_list_global_market',
  API_TYPE: 'MGC'
};

export const operationalEmergingRisksConstants = {
  CMS_LAYOUT: 'operational_and_emerging_risks'
};

export const targets = {
  BLANK: '_blank',
  SELF: '_self',
  PARENT: '_parent',
  TOP: '_top'
};

export const DIRECTIONS = { ASC: 'asc', DESC: 'desc' };

export const generalMessagesIdentifiers = { TICKER: 'ticker' };

export const learnPageConstants = {
  CMS_LAYOUT: 'learn'
};

export const listOfETFsIssuers = {
  CMS_LAYOUT: 'listOfETFsIssuers'
};

export const Error404PageConstants = {
  CMS_LAYOUT: 'error_404'
};

export const Error500PageConstants = {
  CMS_LAYOUT: 'error_500'
};

export const MAX_HISTORICAL_DATE = '2010-01-01:00:00:00';

export const ColumnsWithVariation = [
  'percentageChange',
  'volume',
  'contractsNumber'
];

export const columnsCsvIndices = {
  date: {
    es: 'Fecha',
    en: 'Date',
    format: 'date'
  },
  todayValue: {
    es: 'Valor hoy',
    en: 'Today´s Value',
    format: 'number'
  },
  yesterdayValue: {
    es: 'Valor ayer',
    en: 'Yesterday´s Value',
    format: 'number'
  },
  absoluteVariation: {
    es: 'Variación absoluta',
    en: 'Absolute change',
    format: 'number'
  },
  percentageChange: {
    es: 'Variación porcentual',
    en: 'Percent change',
    format: 'number'
  },
  variation12Months: {
    es: 'Variación 12 meses',
    en: '12 month variation',
    format: 'number'
  },
  yearVariation: {
    es: 'Variación año',
    en: 'Year variation',
    format: 'number'
  },
  waYieldToMaturity: {
    es: 'TIR',
    en: 'TIR',
    format: 'number'
  },
  waDuration: {
    es: 'Duración',
    en: 'Duration',
    format: 'number'
  }
};

export const ColumnsCsvDerivatives = {
  tradeDate: {
    es: 'Fecha',
    en: 'Date',
    format: 'date'
  },
  symbol: {
    es: 'Contrato',
    en: 'Contract',
    format: 'string'
  },
  close_price: {
    es: 'Precio Cierre',
    en: 'Close price',
    format: 'number'
  },
  price_max: {
    es: 'Precio Máximo',
    en: 'Maximum price',
    format: 'number'
  },
  price_min: {
    es: 'Precio Minimo',
    en: 'Minumum price',
    format: 'number'
  },
  variation_absolute: {
    es: 'Variación absoluta',
    en: 'variation absolute',
    format: 'number'
  },
  variation_percentage: {
    es: 'Variación Porcentual',
    en: 'Percentage change',
    format: 'number'
  },
  contratos: {
    es: 'No. Contratos',
    en: 'No. Contracts',
    format: 'number'
  },
  volumen: {
    es: 'Volumen',
    en: 'Value',
    format: 'number'
  },
  openInterest: {
    es: 'Open Interest',
    en: 'OpenInterest',
    format: 'number'
  }
};

export const columnsCsvVariableIncome = {
  tradeDate: {
    es_CO: 'Fecha',
    en: 'Date',
    format: 'date'
  },
  symbol: {
    es_CO: 'Nemotécnico',
    en: 'Mnemonic',
    format: 'string'
  },
  close_price: {
    es_CO: 'Precio Cierre',
    en: 'Close price',
    format: 'number'
  },
  price_max: {
    es_CO: 'Precio Máximo',
    en: 'Maximum price',
    format: 'number'
  },
  weighted_average_price: {
    es_CO: 'Precio Promedio Ponderado',
    en: 'Weighted average price',
    format: 'number'
  },
  price_min: {
    es_CO: 'Precio Mínimo',
    en: 'Minumum price',
    format: 'number'
  },
  variation_absolute: {
    es_CO: 'Variación absoluta',
    en: 'variation absolute',
    format: 'number'
  },
  variation_percentage: {
    es_CO: 'Variación Porcentual',
    en: 'Percentage change',
    format: 'number'
  },
  contratos: {
    es_CO: 'Cantidad',
    en: 'Quantity',
    format: 'number'
  },
  volumen: {
    es_CO: 'Volumen',
    en: 'Volume',
    format: 'number'
  }
};

export const columnsCsvRFPub = {
  tradeDate: {
    es: 'Fecha',
    en: 'Date',
    format: 'date'
  },
  symbol: {
    es: 'Nemotécnico',
    en: 'Symbol',
    format: 'string'
  },
  board: {
    es: 'Rueda',
    en: 'Board',
    format: 'string'
  },
  lastPx: {
    es: 'Último Precio',
    en: 'Last price',
    format: 'number',
    decimals: 3
  },
  highPx: {
    es: 'Precio Máximo',
    en: 'Max Price',
    format: 'number',
    decimals: 3
  },
  waPx: {
    es: 'Precio promedio ponderado',
    en: 'Weighted average price',
    format: 'number',
    decimals: 3
  },
  lowPx: {
    es: 'Precio Minimo',
    en: 'Min Price',
    format: 'number',
    decimals: 3
  },
  lastYield: {
    es: 'Última tasa',
    en: 'Last Rate',
    format: 'number',
    decimals: 3
  },
  highYield: {
    es: 'Tasa máxima',
    en: 'Max Price',
    format: 'number',
    decimals: 3
  },
  waYield: {
    es: 'Tasa Promedio Ponderada',
    en: ' Weighted Avg Rate',
    format: 'number',
    decimals: 3
  },
  lowYield: {
    es: 'Tasa Mímima',
    en: 'Min Rate',
    format: 'number',
    decimals: 3
  },
  changePercent: {
    es: 'Variación Porcentual',
    en: 'change Percent',
    format: 'number',
    decimals: 2
  },
  refYield: {
    es: 'Variacion Pbs',
    en: 'Pbs varation',
    format: 'number',
    decimals: 2
  },
  tradeVolume: {
    es: 'Cantidad',
    en: 'Quantity',
    format: 'number',
    decimals: 2
  },
  tradeValue: {
    es: 'Volumen',
    en: 'Volume',
    format: 'number',
    decimals: 2
  }
};

export const columnsCsvRFPubNotStandarized = {
  tradeDate: {
    es: 'Fecha',
    en: 'Date',
    format: 'date'
  },
  symbol: {
    es: 'Nemotécnico',
    en: 'Symbol',
    format: 'string'
  },
  board: {
    es: 'Rueda',
    en: 'Board',
    format: 'string'
  },
  lastPx: {
    es: 'Último Precio',
    en: 'Last price',
    format: 'number',
    decimals: 3
  },
  lastYield: {
    es: 'Última tasa',
    en: 'Last Rate',
    format: 'number',
    decimals: 3
  },
  tradeVolume: {
    es: 'Cantidad',
    en: 'Quantity',
    format: 'number',
    decimals: 2
  },
  tradeValue: {
    es: 'Volumen',
    en: 'Volume',
    format: 'number',
    decimals: 2
  }
};

export const columnsCsvRFPriv = {
  tradeDate: {
    es: 'Fecha',
    en: 'Date',
    format: 'date'
  },
  symbol: {
    es: 'Nemotécnico',
    en: 'Symbol',
    format: 'string'
  },
  board: {
    es: 'Rueda',
    en: 'Board',
    format: 'string'
  },
  lastPx: {
    es: 'Último Precio',
    en: 'Last price',
    format: 'number',
    decimals: 3
  },
  highPx: {
    es: 'Precio Máximo',
    en: 'Max Price',
    format: 'number',
    decimals: 3
  },
  waPx: {
    es: 'Precio promedio ponderado',
    en: 'Weighted Avg',
    format: 'number',
    decimals: 3
  },
  lowPx: {
    es: 'Precio Minimo',
    en: 'Min Price',
    format: 'number',
    decimals: 3
  },
  lastYield: {
    es: 'Última tasa',
    en: 'Last Rate',
    format: 'number',
    decimals: 3
  },
  highYield: {
    es: 'Tasa máxima',
    en: 'Max Price',
    format: 'number',
    decimals: 3
  },
  lowYield: {
    es: 'Tasa Mímima',
    en: 'Min Rate',
    format: 'number',
    decimals: 3
  },
  waYield: {
    es: 'Tasa Promedio Ponderada',
    en: ' Weighted Avg Rate',
    format: 'number',
    decimals: 3
  },
  changePercent: {
    es: 'Variación Porcentual',
    en: 'Change Percent',
    format: 'number',
    decimals: 2
  },
  refYield: {
    es: 'Variacion Pbs',
    en: 'Pbs varation',
    format: 'number',
    decimals: 2
  },
  tradeVolume: {
    es: 'Cantidad',
    en: 'Quantity',
    format: 'number',
    decimals: 2
  },
  tradeValue: {
    es: 'Volumen',
    en: 'Volume',
    format: 'number',
    decimals: 2
  }
};

export const columnsCsvRFPrivNotStandarized = {
  tradeDate: {
    es: 'Fecha',
    en: 'Date',
    format: 'date'
  },
  symbol: {
    es: 'Nemotécnico',
    en: 'Symbol',
    format: 'string'
  },
  board: {
    es: 'Rueda',
    en: 'Board',
    format: 'string'
  },
  lastPx: {
    es: 'Último Precio',
    en: 'Last price',
    format: 'number',
    decimals: 3
  },
  lastYield: {
    es: 'Última tasa',
    en: 'Last Rate',
    format: 'number',
    decimals: 3
  },
  tradeVolume: {
    es: 'Cantidad',
    en: 'Quantity',
    format: 'number',
    decimals: 2
  },
  tradeValue: {
    es: 'Volumen',
    en: 'Volume',
    format: 'number',
    decimals: 2
  }
};

export const identifiersRf = {
  summary: 'summary',
  historical: 'historical',
  information_title: 'information_title',
  primary_banner: 'fixed_rent_primary_banner',
  secondary_banner: 'fixed_rent_secondary_banner',
  information_operations: 'information_operations',
  prices_and_volumes_rate: 'prices_and_volumes_rate',
  prices_and_volumes_price: 'prices_and_volumes_price',
  information_operations_ttv: 'information_operations_ttv',
  information_operations_repo: 'information_operations_repo',
  information_operations_contado: 'information_operations_contado',
  information_operations_simultaneas: 'information_operations_simultaneas',
  tabs: {
    operations: 'fixed_rent_operations',
    summary: 'fixed_rent_summary'
  }
};
