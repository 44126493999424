import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import tempConfigActions from '@redux/reducers/tempConfig';

const useTempConfig = () => {
  const dispatch = useDispatch();
  const { modalIsOpen, modalDownload, modalRedirect } = useSelector(
    ({ tempConfig }) => tempConfig
  );

  const setModalIsOpen = useCallback(
    (value) => dispatch(tempConfigActions.setModalIsOpen(value)),
    [dispatch]
  );

  const setModalDownload = useCallback(
    (value) => dispatch(tempConfigActions.setModalDownload(value)),
    [dispatch]
  );
  const setModalRedirect = useCallback(
    (value) => dispatch(tempConfigActions.setModalRedirect(value)),
    [dispatch]
  );
  return [
    { modalIsOpen, modalDownload, modalRedirect },
    { setModalIsOpen, setModalDownload, setModalRedirect }
  ];
};

export default useTempConfig;
