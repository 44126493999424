import { useRouter as useRouterNext } from 'next/router';

import { targets } from '@config/constants';
import useTempConfig from '@hooks/useTempConfig';
import { isAValidExternalUrl } from '@utils/strings';

const overridedPush = ({
  pathname,
  target,
  setModalRedirect,
  isBVCDomain,
  router,
  args
}) => {
  if (pathname) {
    if (isAValidExternalUrl(pathname) && isBVCDomain(pathname)) {
      window.open(pathname, target);
    } else if (isAValidExternalUrl(pathname)) {
      setModalRedirect({
        isOpen: true,
        action: () => window.open(pathname, target)
      });
    } else if (typeof args[0] === 'object' && args.length === 1) {
      router.push(args[0]);
    } else {
      router.push(...args);
    }
  }
};

const useRouter = () => {
  const router = useRouterNext();
  const { setModalRedirect } = useTempConfig()[1];

  const isBVCDomain = (url) => {
    let bvcDomains = process.env.NEXT_PUBLIC_BVC_DOMAINS;
    bvcDomains = bvcDomains ? JSON.parse(bvcDomains) : [];
    return bvcDomains.filter((domain) => url.includes(domain)).length;
  };

  const push = (...args) => {
    if (typeof args[0] === 'object' && args.length === 1) {
      const [{ pathname, target = targets.BLANK }] = args;
      overridedPush({
        pathname,
        target,
        setModalRedirect,
        isBVCDomain,
        router,
        args
      });
    } else {
      overridedPush({
        pathname: args[0],
        target: args[3],
        setModalRedirect,
        isBVCDomain,
        router,
        args
      });
    }
  };

  const back = () => {
    if (window.history.length < 2) router.replace('/');
    else router.back();
  };

  return { ...router, push, back };
};

export default useRouter;
