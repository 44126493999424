export const fontSizes = {
  SM: 'sm',
  MD: 'md',
  LG: 'lg'
};

export const fontSizeValues = {
  SM: 14,
  MD: 16,
  LG: 18
};
