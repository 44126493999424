export const tabsIdentifiers = {
  VARIABLE_INCOME: 'variable_income',
  INDEXES: 'indexes',
  FIXED_RENT: 'fixed_rent',
  DERIVATIVES: 'derivatives',
  FOREIGN_EXCHANGE: 'foreign_exchange',
  SUMMARY: 'summary',
  OPERATIONS: 'operations'
};

export const indexesProperties = {
  stockholders: 'stockholders',
  fixed_rent: 'fixedRent',
  money_market: 'moneyMarket'
};

export const marketStatus = {
  OPEN: 'OPEN',
  CLOSE: 'CLOSE'
};

export const marketStatusLvl2 = {
  OPEN: 'openMarket',
  CLOSE: 'closedMarket'
};
export const types = {
  RF_PUB: 'rf',
  DIV: 'div',
  DER: 'der',
  RV: 'rv',
  INDEXES: 'indexes'
};

export const tabsIdentifiersToTypes = {
  fixed_rent: 'rf',
  foreing_exchange: 'div',
  derivatives: 'der',
  variable_income: 'rv',
  indexes: 'indexes'
};

export const reversedProperties = {
  [types.DER]: {
    futureMostTraded: 'most_traded_futures',
    optionsMostTraded: 'most_traded_options'
  }
};

export const properties = {
  [types.DIV]: {
    spot_market: 'marketSpot',
    next_day_market: 'nextDay'
  },
  [types.RF_PUB]: {
    most_traded_public_debt: 'publicMostTraded',
    most_traded_corporate_debt: 'privateMostTraded'
  },
  [types.DER]: {
    most_traded_futures: 'futureMostTraded',
    most_traded_options: 'optionsMostTraded'
  },
  [types.RV]: {
    more_valued: 'mostValued',
    more_undervalued: 'mostDevalued',
    most_traded: 'mostTraded'
  }
};

export const propertiesLvl2 = {
  [tabsIdentifiers.VARIABLE_INCOME]: {
    local_market: 'local_market',
    colombian_global_market: 'colombian_global_market'
  },
  [tabsIdentifiers.INDEXES]: {
    stockholders: 'stockholders',
    fixed_rent: 'fixed_rent',
    money_market: 'money_market'
  },
  [tabsIdentifiers.FIXED_RENT]: {
    public_debt_public_segment: 'public_debt_public_segment',
    public_debt_private_segment: 'public_debt_private_segment',
    corporate_debt: 'corporate_debt'
  },
  [tabsIdentifiers.FOREIGN_EXCHANGE]: {
    spot_market: 'spot_market'
  },
  [tabsIdentifiers.DERIVATIVES]: {
    futures: 'futures',
    options: 'options'
  }
};

export const operationTypesValues = {
  CONTADO: 'EQTY',
  TTV: 'TTV',
  REPO: 'REPO'
};

export const operationTypesLabels = {
  CONTADO: 'CONTADO',
  TTV: 'TTV',
  REPO: 'REPO'
};

export const rfPubSegmentsByTab = {
  [propertiesLvl2[tabsIdentifiers.FIXED_RENT].public_debt_public_segment]:
    'A2A',
  [propertiesLvl2[tabsIdentifiers.FIXED_RENT].public_debt_private_segment]:
    'D2D'
};

export const bannersIdentifiers = {
  BANNER: 'banner',
  SECONDARY_BANNER: 'secondary_banner'
};

export const sectionsIdentifiers = {
  PRICES_AND_VOLUMES: 'prices_and_volumes',
  CONTRACT_DETAILS: 'contract_details',
  HISTORICAL: 'historical',
  OPERATIONS: 'operations'
};
export const historicalColumns = [
  'date',
  'mnemonic',
  'quantity',
  'volume',
  'lastPrice'
];

export const intervals = {
  '1D': '1D',
  '1S': '1S',
  '1M': '1M',
  '3M': '3M',
  '6M': '6M',
  '1A': '1A',
  '5A': '5A',
  MAX: 'Max'
};

export const subMarkets = {
  indexRv: 'indexrv',
  indexRfPub: 'indexrfpub',
  moneyMarket: 'money_market'
};

export const subMarketsRF = {
  'FI-PUBLICDEBT': 'public_debt_public_segment',
  'FI-CORPDEBT': 'corporate_debt'
};

export const indexes = { COLIBR: 'colibr' };

export const columnsOnlyOptionsDerN3 = [
  'typeOfExercise',
  'putOrCall',
  'strike'
];

export const templateVariableIncomeN3 = {
  localMarketIssuer: {
    component: 'LocalMarket',
    query: 'getRvLocalMarketIssuer'
  },
  localMarketEtfs: {
    component: 'localMarketEtfs',
    query: 'getRvLocalMarketEtf'
  },
  globalMarketIssuer: {
    component: 'marketGlobalIssuer',
    query: 'getRvMarketGlobalIssuer'
  },
  globalMarketEtfs: {
    component: 'marketGlobalEtfs',
    query: 'getRvMarketGlobalEtfs'
  }
};

export const markets = {
  variableIncome: 'variable-income',
  derivatives: 'derivatives',
  indices: 'indices'
};

export const couponFrequenciesRfPriv = {
  N: 'none',
  D: 'daily',
  MO: 'monthly',
  BM: 'bimonthly',
  QR: 'quarterly',
  FM: 'everyFourMonths',
  SA: 'semiannual',
  YR: 'annual',
  BY: 'biannual',
  P: 'periodic'
};

export const sectionsOperationTabRvLvl3 = {
  information_operations: 'information_operations',
  information_operations_contado: 'information_operations_contado',
  information_operations_repo: 'information_operations_repo',
  information_operations_ttv: 'information_operations_ttv',
  information_operations_simultaneas: 'information_operations_simultaneas'
};

export const identifiersRV = {
  marketGlobalIssuer: {
    info_participant: 'info_participant',
    banner_main: 'banner_main',
    fundamentals: 'fundamentals',
    info_summary: 'info_summary',
    prices_and_volumes: 'prices_and_volumes',
    fundamental: 'fundamental',
    stock_information: 'stock_information',
    historics: 'historics',
    banner_secondary: 'banner_secondary',
    banner_bvc: 'banner_bvc',
    information_issuer: 'information',
    information_general: 'information_general',
    ...sectionsOperationTabRvLvl3,
    tabs: {
      information: 'information',
      summary: 'summary',
      operations: 'operations'
    }
  },
  marketGlobalEtfs: {
    banner_main: 'banner_main',
    banner_secondary: 'banner_secondary',
    banner_bvc: 'banner_bvc',
    info_participant: 'info_participant',
    information_etf: 'information',
    information_general: 'information_general',
    ...sectionsOperationTabRvLvl3,
    info_summary: 'info_summary',
    prices_and_volumes: 'prices_and_volumes',
    fundamental: 'fundamental',
    stock_information: 'stock_information',
    historics: 'historics',
    tabs: {
      information: 'information',
      summary: 'summary',
      operations: 'operations'
    }
  },
  localMarketEtfs: {
    issuer_titles: 'issuer_titles',
    banner_bvc: 'banner_bvc',
    banner_main: 'banner_main',
    info_summary: 'info_summary',
    prices_and_volumes: 'prices_and_volumes',
    fundamental: 'fundamental',
    stock_information: 'stock_information',
    historics: 'historics',
    info_participant: 'info_participant',
    banner_secondary: 'banner_secondary',
    information: 'information',
    information_general: 'information_general',
    ...sectionsOperationTabRvLvl3,
    tabs: {
      information: 'information',
      summary: 'summary',
      operations: 'operations'
    }
  },
  localMarketIssuer: {
    issuer_titles: 'issuer_titles',
    banner_bvc: 'banner_bvc',
    banner_main: 'banner_main',
    info_summary: 'info_summary',
    prices_and_volumes: 'prices_and_volumes',
    fundamental: 'fundamental',
    stock_information: 'stock_information',
    historics: 'historics',
    info_participant: 'info_participant',
    banner_secondary: 'banner_secondary',
    issuer_information: 'issuer_information',
    financial_information: 'financial_information',
    ...sectionsOperationTabRvLvl3,
    tabs: {
      information: 'information',
      summary: 'summary',
      operations: 'operations'
    }
  }
};

export const tabsVariableIncomeLvl3 = {
  informacion: 'information',
  information: 'information',
  summary: 'summary',
  resumen: 'summary'
};

export const defaultSubMarkets = {
  [types.RV]: propertiesLvl2[tabsIdentifiers.VARIABLE_INCOME].local_market,
  [types.RF_PUB]:
    propertiesLvl2[tabsIdentifiers.FIXED_RENT].public_debt_public_segment
};

export const sectionsToOperations = {
  [sectionsOperationTabRvLvl3.information_operations_ttv]: 'ttv',
  [sectionsOperationTabRvLvl3.information_operations_repo]: 'repo',
  [sectionsOperationTabRvLvl3.information_operations_contado]: 'contado',
  [sectionsOperationTabRvLvl3.information_operations_simultaneas]: 'simultaneo'
};

export const fixedRentSegment = {
  public_debt_private_segment: 'D2D',
  public_debt_public_segment: 'A2A'
};

export const priceAndVolumesOptions = { PRICE: 'price', RATE: 'rate' };

export const fixedRentPages = {
  FIXED_RENT_PRIVATE: 'fixed_rent_private',
  FIXED_RENT_PUBLIC: 'fixed_rent_public',
  FIXED_RENT_CORPORATE_PRIMARY: 'fixed_rent_corporate_primary',
  FIXED_RENT_CORPORATE_SECONDARY: 'fixed_rent_corporate_secondary'
};

export const tableFooterML2 = {
  corporate_debt: 'corporate_debt',
  countedSecundary: 'contado secundario',
  countedPrimary: 'contado primario',
  simultaneas: 'simultaneas',
  tradedVolume: 'tradedVolume',
  registeredVolume: 'registeredVolume'
};
