export const upperCaseFirstLetter = (
  string,
  blacklist = [],
  toLowerCase = true
) => {
  if (blacklist.includes(string)) {
    return string;
  }
  if (toLowerCase) {
    return `${string.charAt(0).toUpperCase()}${string.slice(1).toLowerCase()}`;
  }
  return `${string.charAt(0).toUpperCase()}${string.slice(1)}`;
};

export const splitWords = (string = ' ', split = ' ') =>
  string ? string.split(split) : [''];

export const sliceAndJoin = (array, limits, join = ' ') =>
  array.slice(...limits).join(join);

export const isAValidExternalUrl = (url) =>
  /^(ftp|http|https):\/\/[^ "]+$/.test(url);

export const isAValidWebPage = (url) =>
  /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/gm.test(
    url
  );

export const getEmbedYoutubeUrl = (url) =>
  url?.length > 0
    ? `https://www.youtube.com/embed/${url?.split('https://youtu.be/')[1]}`
    : undefined;

export const matchParagraphTag = (htmlString) =>
  htmlString.match(/<\s*p[^>]*>(.*?)<.p>/g);

export const toLowerDashedCase = (text) =>
  text.trim().replace(/\s/g, '-').toLowerCase();

// It don't have to remove the accents, instead you can use the removeAccentsMark utility
export const removeSpecialCharacters = (str) =>
  str.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>{}[\]\\/]/gi, '');

export const toCamelCase = (str) =>
  str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
    if (+match === 0) return '';
    return index === 0 ? match.toLowerCase() : match.toUpperCase();
  });

export const removeDashes = (text) => `${text}`.replace(/-/g, ' ');

export const strongTagRgx = /<\s*strong[^>]*>(.*?)<.strong>/g;

export const removeAnchor = (str) => {
  if (str?.includes('#')) {
    return splitWords(str, '#')[0];
  }
  return str;
};

export const removeAccentsMark = (text = '') =>
  text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

// Util to remove character from string, for example a comma ','
export const removeCharacter = (str, character = ';') =>
  str.replace(new RegExp(character, 'g'), '');

export const replaceCharacter = (
  str = ' ',
  character = ' ',
  replaceWith = undefined
) => str.replace(new RegExp(character, 'g'), replaceWith);

export const includes = (originalStr, compareStr) =>
  String(originalStr)?.toLowerCase().includes(String(compareStr).toLowerCase());

export const isEquals = (originalStr, compareStr) =>
  String(originalStr)?.toLowerCase() === String(compareStr).toLowerCase();

export const isYoutubeUrl = (url) =>
  url.match(
    /http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch?v=|\.be\/)([\w\-_]*)(&(amp;)?‌[\w?‌=]*)?/g
  );

export const generateEllipsis = (text, init, end) =>
  text.length > end ? `${text.slice(init, end).trim()}...` : text;

export const getMailTo = (str, url = null) =>
  /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(str) ? `mailto:${str}` : url;

export const isValidEmail = (text) => {
  const expression = /[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]{2,4}/gim;
  return expression.test(text);
};

export const dashedCaseToCamel = (text) => {
  const strToArray = Array.from(text.toLowerCase());
  strToArray.forEach((char, index) => {
    if (char === '_') {
      const nextIndex = index + 1;
      if (strToArray[nextIndex]) {
        strToArray[nextIndex] = strToArray[nextIndex].toUpperCase();
      }
    }
  });
  const joinedStr = strToArray.join('');
  return replaceCharacter(joinedStr, /_/, '');
};
export const includesFileType = (fileName, fileTypes = ['.pdf']) =>
  fileTypes.some((fileType) => includes(fileName, fileType));

export const removeDashMnemonic = (mnemonic = '') => {
  const [slitedMnemonic] = includes(mnemonic, '-')
    ? splitWords(mnemonic, '-')
    : splitWords(mnemonic, '_');
  return slitedMnemonic;
};

export const splitTitleClass = (str = '') => splitWords(str, '.').join(' ');
